import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import { storeService } from "../services/StoreService";
import { storeName } from "../services/StoreNameService";
import { CircularProgress, Button } from "@material-ui/core";

const styles = {
	root: {
		margin: "50px 10px",
		display: 'grid',
		rowGap: "40px",
		justifyContent: 'center',
	},
	storeLink: {
		display: "grid",
		textAlign: "center",
	},
	logo: {
		maxHeight: "70px",
		justifySelf: "center",
	},
	storeName: {
		fontSize: "20px",
		fontWeight: "600",
	},
	h3: {
		marginBottom: "-18px",
		textAlign: "center",
		fontSize: "14px",
		fontWeight: "700",
	},
	hoursWrapper: {
		display: 'grid',
		gridTemplateColumns: "auto 15px auto",
		rowGap: "10px",
	},
	dayRow: {
		display: "contents",
	},
	dayName: {
		textAlign: "right",
		fontWeight: "600",
	},
	colon: {
		textAlign: "center",
	},
	hours: {

	},
};

const dayNameConverter = new Intl.DateTimeFormat("en-US", { weekday: "long" });
const timeConverter = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric' });

class AfterHours extends React.Component {
	state = {
		isLoading: true,
	}

	componentDidMount() {
		storeService.getStoreAsync()
			.then(() => {
				this.setState({ isLoading: false });
				console.log(storeService);
			});
	}

	getDayName(i) {
		let d = new Date();
		d.setDate(d.getDate() + i);
		return dayNameConverter.format(d);
	}

	getDayHours(dayName, daysFromToday) {
		const openingTimeKey = `opensAt${dayName}`,
			closeTimeKey = `closesAt${dayName}`,
			isOpenToday = `open${dayName}`;

		if (!storeService.store.storeConfig[isOpenToday]) {
			return <span>Closed</span>
		}

		const openingTime = storeService.store.storeConfig[openingTimeKey],
			closingTime = storeService.store.storeConfig[closeTimeKey];

		let day = new Date();
		day.setDate(day.getDate() + daysFromToday);
		const dateBase = new Intl.DateTimeFormat('en-US').format(day);

		const openingDate = new Date(`${dateBase} ${openingTime}`),
			closingDate = new Date(`${dateBase} ${closingTime}`);

		return (
			<span>{timeConverter.format(openingDate)} - {timeConverter.format(closingDate)}</span>
		);
	}

	tryAgain = () => {
		console.log(storeService.storeIsOpenNow())
		if (storeService.storeIsOpenNow()) {
			this.props.history.push('/' + storeName);
		}
	}

	renderLoader(classes) {
		return <CircularProgress />
	}

	renderHours(classes) {
		return (
			<div className={classes.root}>
				<a
					href={storeService.store.storeWebsiteUrl}
					className={classes.storeLink}
				>
					{storeService.store.logo && (
						<img
							className={classes.logo}
							src={storeService.store.logo}
							alt={storeService.store.name}
						/>
					)}
					<h1 className={classes.storeName}>{storeService.store.name}</h1>
				</a>
				<h2>Curbside is not available at this time</h2>
				<h3 className={classes.h3}>Curbside Hours</h3>
				<div className={classes.hoursWrapper}>
					{[...Array(7)].map((x, i) => (
						<p className={classes.dayRow} key={i}>
							<span className={classes.dayName}>{this.getDayName(i)}</span>
							<span className={classes.colon}>:</span>
							<span className={classes.hours}>{this.getDayHours(this.getDayName(i), i)}</span>
						</p>
					))}
				</div>
				<Button onClick={this.tryAgain} variant="contained" color="primary">Try Again Now</Button>
			</div>
		);
	}

	render() {
		const classes = this.props.classes;
		return this.state.isLoading ? this.renderLoader(classes) : this.renderHours(classes);
	}
}

export default withStyles(styles)(AfterHours);
