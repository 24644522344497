import { apiService } from "./ApiService";
import { storeName } from "./StoreNameService";

class StoreService {
	store;
	_apiPromise;
	get storeName() {
		return storeName;
	}

	constructor(){
		this.getStoreMain();
	}

	getStoreMain(){
		this._apiPromise = apiService.get("ping/info")
			.then(store => {
				console.log(store);
				this.store = store;
				this.updateTitleAsync();
				return store;
			})
			.catch(console.error);
	}

	async getStoreAsync() {
		if(this.store) Promise.resolve(this.store);
		else return this._apiPromise;
	}

	storeIsOpenNow(){
		const now = new Date;
		const weekDayName = new Intl.DateTimeFormat('en-US', {weekday: "long"}).format(now);

		const openingTimeKey = `opensAt${weekDayName}`,
			closeTimeKey = `closesAt${weekDayName}`,
			isOpenToday = `open${weekDayName}`;

		if(!this.store.storeConfig[isOpenToday]) return false;

		const openingTime = this.store.storeConfig[openingTimeKey],
			closingTime = this.store.storeConfig[closeTimeKey];

		const dateBase = new Intl.DateTimeFormat('en-US').format(now);
		const openingDate = new Date(`${ dateBase } ${ openingTime }`),
			closingDate = new Date(`${ dateBase } ${ closingTime }`);
		
		const isOpenNow = now > openingDate && now < closingDate;

		console.log(openingDate, closingDate);
		

		return isOpenNow;
	}

	updateTitleAsync(){
		document.title = `${ this.store.name } | ${ document.title }`;
	}
}
export let storeService = new StoreService();
