import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Success from './Success';
import { storeService } from '../services/StoreService';
import { apiService } from '../services/ApiService';
import StarRating from './Rating';
import { Typography, TextField, Button, Box } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { Alert, AlertTitle, Card } from "@mui/material"
import { CircularProgress } from '@material-ui/core';

const styles = {
	root: {
		width: "70%",
		margin: "auto",
	},
	h1: {
		textAlign: "center",
		fontSize: "22px",
		margin: "30px 0",
	},
	h2: {
		textAlign: "center",
		fontSize: "22px",
	},
	feedbackContainer: {
		textAlign: "center",
	},
	hidden: {
		display: "none",
	},
	block: {
		display: "block",
	},
	starContainer: {
		margin: "20px 0",
	},
};

const Thanks = ({ classes }) => {
	const [store, setStore] = useState(null);
	const [storeRating, setStoreRating] = useState(null);
	const [appRating, setAppRating] = useState(null);
	const [feedback, setFeedback] = useState('');
	const [hidden, setHidden] = useState(true);

	const history = useHistory();

	useEffect(() => {
		if (!localStorage.getItem("pingId")) {
			history.push(`/${storeService.storeName}/start`);
			return;
		}

		window.addEventListener('unload', () => localStorage.removeItem('pingId'));
		// storeService
		// 	.getStoreAsync()
		// 	.then(store => {
		// 		console.log(store);
		// 		if (store !== undefined) setStore(store)
		// 	});
		setStore(storeService.store);

		return () => window.removeEventListener('unload', () => localStorage.removeItem('pingId'));
	},
	[]);

	useEffect(() => {
		if (storeRating > 0 && appRating > 0) {
			if (storeRating < 4) setHidden(false);
		}
	}, [storeRating, appRating]);

	const rateStore = (rating) => {
		let pingId = localStorage.getItem("pingId");
		apiService
			.post(`ping/rate/${pingId}`, { storeRating: rating })
			.then(setStoreRating(rating));
	}

	const rateApp = (rating) => {
		let pingId = localStorage.getItem("pingId");
		apiService
			.post(`ping/rate/${pingId}`, { appRating: rating })
			.then(setAppRating(rating));
	}

	const submitFeedback = () => {
		let pingId = localStorage.getItem("pingId");
		apiService
			.post(`ping/rate/${pingId}`, { feedback: feedback });

		localStorage.removeItem('pingId');
		history.push(`/${storeService.storeName}/start`);
	}

	return (
		store === null ?
			<CircularProgress />
			: <Card elevation={0} className={classes.root}>
				<h1 className={classes.h1}>Thanks for using Curbside Bell!</h1>
				{/* <Success /> */}

				<div className={classes.starContainer} visible={store.storeConfig.rateStore.toString()}>
					{!storeRating ?
						<>
							<h1 className={classes.h2}>Please rate your experience with {store?.name}</h1>
							<StarRating onClick={rateStore} />
						</>
						: <Alert severity="success">
							<AlertTitle>Thank you for rating {store?.name}</AlertTitle>
						</Alert>
					}
				</div>

				<div className={classes.starContainer}
					visible={store.storeConfig.rateStore.toString() && store.storeConfig.rateCurbsidebell.toString()}>
					{!appRating ?
						<>
							<h1 className={classes.h2}>Please rate Curbside Bell</h1>
							<StarRating onClick={rateApp} />
						</>
						: <Alert severity="success">
							<AlertTitle>Thank you for rating Curbside Bell</AlertTitle>
						</Alert>
					}
				</div>

				{
					!hidden &&
					<div className={classes.feedbackContainer}>
						<Typography variant='h6' component='div' style={{ marginBottom: '25px' }}>
							{
								storeRating <= 3 ? 'Thank you for your feedback. Let us know how we can improve our service.'
									: 'Thank you for your feedback! Please consider sharing your experience.'
							}
						</Typography>
						{
							storeRating <= 3 &&
							<>
								<TextField multiline
									value={feedback}
									variant='outlined'
									rows={4}
									placeholder='Feedback'
									onChange={(e) => setFeedback(e.target.value)}
									margin='normal'
									inputProps={{ style: { width: '350px', } }}
									style={{ display: 'block' }}
								/>
								<Button onClick={submitFeedback}>Submit Feedback</Button>
							</>
						}
					</div>
				}
			</Card>
	);
}

export default withStyles(styles)(Thanks);
