import React from 'react';

class Home extends React.Component {

	render() {
		return (
			<h1>CurbsideBell.com</h1>
		);
	}
}

export default Home;
