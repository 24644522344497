import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import EventTarget from "@ungap/event-target";

class PingService extends EventTarget {
	ping;
	connection;

	start(ping){
		this.ping = ping;
		localStorage.setItem("pingId", this.ping.id);
	}

	setupSocket(pingId){
		this.connection = new HubConnectionBuilder()
			.withUrl(`${process.env.REACT_APP_API_ENDPOINT}customerHub?pingId=${pingId}`)
			.configureLogging(LogLevel.Information)
			.build();

		this.connection.on("message", message => {
			console.log(message);
			this.ping.chatMessageList.push(message);
			this.dispatchEvent(new CustomEvent("message", {detail: { message }}));
		});
		this.connection.on("close", closedAs => {
			console.log(closedAs);
			//localStorage.removeItem("pingId"); //need it for success page
			this.dispatchEvent(new CustomEvent("close", {detail: { closedAs }}));
		});		
		this.connection.on('response-change', allowed => {
			console.log(pingId);
			this.dispatchEvent(new CustomEvent('response-change', { detail: { allowed } }));
		});

		this.connection.onclose(() => {
			this.reconnect();
		});

		this.startSocket();
	}

	reconnect(){
		console.log("Reconnecting..");
		this.startSocket()
			.then(() => {
				console.log('Reconnected!');
				let lastMessageDateTimeSent;
				if(this.ping.chatMessageList.length > 0)
					lastMessageDateTimeSent = this.ping.chatMessageList[this.ping.chatMessageList.length - 1].dateTimeSent;
				this.getMessagesAfter(lastMessageDateTimeSent);
			})
			.catch(err => {
				console.log(err);
			});
	}

	startSocket(){
		return this.connection.start()
			.catch(err => {
				setTimeout(() => {
					this.reconnect();
				}, 1000);
				throw err;
			});
	}

	sendMessage(message){
		this.connection
			.invoke("message", message)
			.catch(err => console.error(err));
	}

	getMessagesAfter(lastMessageDate){
		this.connection
			.invoke("get-messages-after", lastMessageDate)
			.catch(err => console.error(err));
	}

	close(){
		this.connection
			.invoke("close", "clientClosed")
			.catch(err => console.error(err));
	}
}
export let pingService = new PingService();
