import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { instanceOf } from 'prop-types';

import { apiService } from '../services/ApiService';
import { pingService } from '../services/PingService';
import { storeService } from '../services/StoreService';
import { mapService } from '../services/MapService';
import Map from '../components/Map';

const styles = {
  root: {
    display: 'grid',
    justifyContent: 'center',
    alignContent: 'center',
    height: 'calc(100% - 70px)',
  },
  loader: {},
  form: {
    display: 'grid',
    rowGap: '30px',
  },
  storeLink: {
    display: 'block',
    marginBottom: '40px',
    textAlign: 'center',
    textDecoration: 'inherit',
    color: 'inherit',
  },
  logo: {
    maxWidth: '150px',
    maxHeight: '150px',
    justifySelf: 'center',
  },
  storeName: {
    fontSize: '20px',
    fontWeight: '600',
  },
};

class Start extends React.Component {

  state = {
    isLoading: true,
    redirectTo: undefined,
    fieldsToSubmit: undefined,
  };

  searchParams;

  constructor() {
    super();
    this.searchParams = new URLSearchParams(window.location.search);
  }

  componentDidMount() {
    this.stopLoadingWhenStoreResolves();
  }

  stopLoadingWhenStoreResolves() {
    storeService
      .getStoreAsync()
      .then((store) => {
        if (!storeService.storeIsOpenNow()) {
          this.props.history.push('after-hours');
        }
        if (localStorage.getItem('pingId')) {
          this.props.history.push('live');
        }
        return store;
      })
      .then((store) => {
        this.setState({ isLoading: false });
      })
      .catch(console.error);
  }

  async checkIfOutsideOfLocation() {
    let isInLocation = await mapService.isCustomerInLocation();
    if (isInLocation) this.submitFieldsToServer();
    else this.setState({ isLoading: false });
  }

  tryAgainLocation = () => {
    this.setState({ isLoading: true });
    this.checkIfOutsideOfLocation();
  };

  async submitFieldsToServer() {
    try {
      let fieldsToSubmit = this.state.fieldsToSubmit;
      fieldsToSubmit.recaptchaToken = await this.getRecaptchaToken();
      let e = await apiService.post(`ping`, this.state.fieldsToSubmit);
      console.log(e);
      pingService.start(e);
      this.props.history.push(`/${storeService.storeName}/live`);
    } catch (err) {
      this.setState({ isLoading: false });
      console.error(err);
    }
  }

  async getRecaptchaToken() {
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY)
          .then(resolve);
      });
    });
  }

  onFormSubmit = async (e) => {
    e.preventDefault();
    let fields = this.formToObject(e.target);
    let obj = { fields };
    obj.InitialChatMassage = obj.fields.curbsideBellInitialChatMessage;
    delete obj.fields.curbsideBellInitialChatMessage;
    await this.setState({ isLoading: true, fieldsToSubmit: obj });

    if (storeService.store.storeConfig.locationRequired)
      this.checkIfOutsideOfLocation();
    else this.submitFieldsToServer();
  };

  formToObject(form) {
    return Object.fromEntries(new FormData(form));
  }

  renderForm(classes) {
    return (
      <>
        <style>{`
					.grecaptcha-badge {
						visibility: visible;
					}
				`}</style>
        <form className={classes.form} onSubmit={this.onFormSubmit}>
          <a
            href={storeService.store.storeWebsiteUrl}
            className={classes.storeLink}
          >
            {storeService.store.logo && (
              <img
                className={classes.logo}
                src={storeService.store.logo}
                alt={storeService.store.name}
              />
            )}
            <h1 className={classes.storeName}>{storeService.store.name}</h1>
          </a>
          {storeService.store.fields.map((field) => (
            <TextField
              name={field.name}
              key={field.name}
              required={field.required}
              label={field.name}
              variant='outlined'
              defaultValue={this.searchParams.get(field.name) || ''}
            />
          ))}
          <TextField
            name='curbsideBellInitialChatMessage'
            label='Message'
            variant='outlined'
            rows={2}
            multiline
          />
          <Button type='submit' variant='contained' color='primary'>
            I'm here
          </Button>
        </form>
      </>
    );
  }

  renderMap(classes) {
    return <Map tryAgain={this.tryAgainLocation} />;
  }

  renderLoader(classes) {
    return <CircularProgress className={classes.loader} />;
  }

  render() {
    const classes = this.props.classes;
    let innerComponent;
    if (this.state.isLoading) innerComponent = this.renderLoader(classes);
    else if (!this.state.fieldsToSubmit)
      innerComponent = this.renderForm(classes);
    else innerComponent = this.renderMap(classes);

    return <div className={classes.root}>{innerComponent}</div>;
  }
}

export default withStyles(styles)(Start);
