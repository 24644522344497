import React, { useEffect, useState } from 'react';
import ChatUI from './ChatUI';
import { withStyles } from '@material-ui/core/styles';
import { Button, Fab, InputBase } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { pingService } from "../../services/PingService";
import { storeService } from '../../services/StoreService';
import { apiService } from '../../services/ApiService';

const styles = {
	wrapper: {
		display: "grid",
		justifyContent: "center",
		marginTop: "40px",
	},
	banner: {
		padding: "10px",
		textAlign: "center",
		lineHeight: "1.8em",
		backgroundColor: "#8080801a",
	},
	messageInput: {
		border: 'solid #00000091 1px;',
		borderRadius: 40,
		paddingLeft: '10px'
	},
	btn: {
		minWidth: '185px'
	},
};

const Live = (props) => {
	const [messageList, setMessageList] = useState([]);
	const [showReminder, setShowReminder] = useState(false);
	const [allowResponse, setAllowResponse] = useState(false);
	const classes = props.classes;

	const restartPingIfNeeded = async () => {
		if (pingService.ping) return; // already have a ping object

		//const previousPingId = sessionStorage.getItem("pingId");
		const previousPingId = localStorage.getItem("pingId");
		if (previousPingId) { // reconnect to ping
			let ping = await apiService.get(`ping/${previousPingId}`);
			if (!ping.endedAs) // ping still open
				await pingService.start(ping);
			if (ping.dateTimeEnded !== null)
				localStorage.removeItem("pingId");
		}
	}

	useEffect(() => {
		async function setup() {
			await restartPingIfNeeded();

			if (!pingService.ping) {
				props.history.push(`/${storeService.storeName}/start`);
				return;
			}
			console.log(pingService, pingService.ping.id);
			pingService.setupSocket(pingService.ping.id);
			pingService.addEventListener("message", receiveMessage);
			pingService.addEventListener("close", showThanks);
			pingService.addEventListener('response-change', changeResponse);
			setupReminderTimer();

			setMessageList(pingService.ping.chatMessageList)
			setAllowResponse(storeService.store.storeConfig.allowResponse || pingService.ping.allowResponse);
		}
		setup();
	}, []);

	const setupReminderTimer = () => {
		setTimeout(() => {
			setShowReminder(true)
		}, storeService.store.storeConfig.secondsToRemind * 1000);
	}

	const remind = () => {
		setShowReminder(false)
		pingService.sendMessage("Reminder");
		setupReminderTimer();
	}

	const receiveMessage = e => {
		e.detail.message.dateTimeSent = new Date(e.detail.message.dateTimeSent);
		setMessageList(Array.from(pingService.ping.chatMessageList))
	}

	const showThanks = e => {
		props.history.push("thanks");
	}

	const endChat = () => {
		pingService.close();
	}

	const changeResponse = e => {
		setAllowResponse(e.detail.allowed);
	}

	return (
		<div>
			<div className={classes.banner}>
				{storeService.store?.name} was notified that you're here.
				<br />
				Someone will be out with your order shortly.
			</div>			
			
			{showReminder && <div className={classes.wrapper}>
				<Button
					variant="contained"
					color="primary"
					onClick={remind}
					className={classes.btn}
				>
					Send Reminder
					&nbsp;
					<NotificationsIcon />
				</Button>
			</div>}

			<ChatUI messageList={messageList} allowResponse={allowResponse} />

			<div className={classes.wrapper}>
				<Button
					variant="contained"
					color="primary"
					onClick={endChat}
					className={classes.btn}
				>
					Received Order
				</Button>
			</div>
		</div>
	);
}

export default withStyles(styles)(Live);
