import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	root: {
		textAlign: "center",
		fontSize: "20px",
		marginTop: "40px",
	},
});

export default function PingError() {
	const classes = useStyles();
	return (
		<p className={classes.root}>An error ocurred 😔</p>
	)
}
