import { storeName } from "./StoreNameService";

class ApiService {
	get(call) {
		return this.makeApiCall(call, 'GET');
	}

	post(call, data) {
		return this.makeApiCall(call, 'POST', data);
	}

	put(call, data) {
		return this.makeApiCall(call, 'PUT', data);
	}

	delete(call) {
		return this.makeApiCall(call, 'DELETE');
	}

	makeApiCall(call, method, data) {
		data = JSON.stringify(data);

		return new Promise((resolve, reject) => {
			fetch(process.env.REACT_APP_API_ENDPOINT + storeName + "/customer/" + call, {
				method,
				body: data,
				headers: {"content-type": "application/json"}
			})
				.then(res => this.jsonParser(res))
				.then(resolve)
				.catch(err => {
					reject(err);
				});
		});
	}

	JSONDateParser(key, value) {
		// const reISO = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/;
		const reISO = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/;
		if (typeof value === 'string') {
			if (reISO.exec(value)) { // is date
				return new Date(value);
			}
		}
		return value;
	}

	async jsonParser(response) {
		const text = await response.text();
		//console.time("json with date");
		let json = JSON.parse(text, this.JSONDateParser);
		//console.timeEnd("json with date");
		if (json.error) {
			this.handleBackendErrorList(json.error);
			throw json.error;
		}
		return json;
	}
}
export let apiService = new ApiService();
