import React from 'react';
import { ChatItem } from "./ChatItem";
import { pingService } from "../../services/PingService";
import { Fab, InputBase } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { withStyles } from '@material-ui/core/styles';
import { storeService } from "../../services/StoreService";

const styles = {
	messageInput: {
		border: 'solid #00000091 1px;',
		borderRadius: 40,
		paddingLeft: '10px',
		width: "100%",
	},
	container: {		
		minHeight: "150px",
		margin: "20px auto",
		maxWidth: "600px",
		border: "1px solid gray",
		padding: "15px",
		borderRadius: "20px",
		position: "relative",
	},
	inputContainer: {
		width: "100%",
		padding: "5px",
		display: "flex",
		columnGap: "10px",		
		bottom: 0,
		right: 0,
		position: "absolute",
	},
	messageContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "baseline",
		width: "100%",
		marginBottom: "40px",
	},
}
class ChatUI extends React.Component {
	state = {
		messageVal: ''
	}

	sendMessage = (e) => {
		e.preventDefault();
		if (this.state.messageVal !== '')
			pingService.sendMessage(this.state.messageVal);
		this.setState({ messageVal: '' });
	};

	render() {
		const classes = this.props.classes;
		return (
			<div className={classes.container}>
				<div className={classes.messageContainer}>
					{this.props.messageList.map((item, i) =>
						<ChatItem key={i} chatMessage={item} />
					)}
				</div>

				{this.props.allowResponse &&
					<div className={classes.inputContainer}>
						<InputBase
							required
							className={classes.messageInput}
							name="message"
							placeholder="Message..."
							value={this.state.messageVal}
							onChange={(e) => this.setState({ messageVal: e.target.value })}
						/>
						<Fab color="primary" size="small" onClick={this.sendMessage}>
							<SendIcon />
						</Fab>
					</div>}
			</div>
		);
	}
}

export default withStyles(styles)(ChatUI);
