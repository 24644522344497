import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";

import Start from './components/Start';
import Live from './components/Live/Live';
import Thanks from './components/Thanks';
import AfterHours from './components/AfterHours';
import Home from './components/Home';
import './services/SoundService';
import Toolbar from './components/Toolbar';
import PingError from './components/PingError';

let fullvh = window.innerHeight;
document.documentElement.style.setProperty('--vh', `${fullvh * 0.01}px`);
document.documentElement.style.setProperty('--fullvh', `${fullvh}px`);

const useStyles = makeStyles({
	root: {
		height: "var(--fullvh)",
	},
});

function App() {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Toolbar />
			<Router>
				<Switch>
					<Route exact path="/" component={Home} />
					<Redirect exact path="/:storeName/" to={"/:storeName/start" + window.location.search} component={Start} />
					<Route exact path="/:storeName/start" component={Start} />
					<Route exact path="/:storeName/live" component={Live} />
					<Route exact path="/:storeName/thanks" component={Thanks} />
					<Route exact path="/:storeName/after-hours" component={AfterHours} />
					<Route exact path="/:storeName/ping-error" component={PingError} />
				</Switch>
			</Router>
		</div>
	);
}

export default App;
