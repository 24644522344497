import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import { mapService } from '../services/MapService';

const styles = {
	root: {
		display: "grid",
		justifyItems: "center",
		rowGap: "20px",
	},
	map: {
		height: "calc(var(--fullvh) - 250px);",
		width: "calc(100vw - 50px)",
	},
};

class Map extends React.Component {
	mapElement = React.createRef();

	componentDidMount(){
		mapService.initMapOnElement(this.mapElement.current);
	}

	render() {
		const classes = this.props.classes;
		return (
			<div className={classes.root}>
				<Button onClick={this.props.tryAgain} variant="contained" color="primary">Try again</Button>
				<div className={classes.map} ref={this.mapElement} />
			</div>
		);
	}
}

export default withStyles(styles)(Map);
