import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, TextField } from '@material-ui/core';

const styles = {
    '@global': {
        'button': {
            backgroundColor: "transparent",
            border: 'none',
            outline: 'none',
            cursor: 'pointer',
        }
    },
    starContainer: {
        textAlign: 'center',
    },
    star: {
        fontSize: '4em',
    },
    hover: {
        color: '#b4bbe4'
    },
    on: {
        color: '#3f51b5',
    },
    off: {
        color: '#ccc',
    }
};

const StarRating = ({ classes, onClick }) => {
    const [hover, setHover] = useState(0);
    const [rating, setRating] = useState(0);

    return (
        <div className={classes.starContainer}>
            {[...Array(5)].map((star, index) => {
                index += 1;
                return (
                    <button
                        key={index}
                        className={index <= rating ? classes.on : (index <= hover ? classes.hover : classes.off)}
                        onClick={() => {                            
                            onClick(index);
                            setRating(index);
                        }}
                        onMouseEnter={() => setHover(index)}
                        onMouseLeave={() => setHover(0)}>
                        <span className={classes.star}>&#9733;</span>
                    </button>
                );
            })}
        </div>
    );
};

export default withStyles(styles)(StarRating);