import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import MatToolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import { storeService } from '../services/StoreService';

const styles = {
	wrapper: {
		display: 'grid',
		gridTemplateColumns: "1fr 1fr",
		width: "100%",
	},
	curbside: {
		justifySelf: "right",
	},
	storeName: {
	},
};

class Toolbar extends React.Component {

	state = {
		store: undefined,
	}

	componentDidMount(){
		storeService.getStoreAsync()
			.then(store => 
				this.setState({store}));
	}

	render(){
		const classes = this.props.classes;
		return (
			<AppBar position="sticky">
				<MatToolbar>
					<div className={classes.wrapper}>
						<span className={classes.storeName}>{this.state.store?.name}</span>
						<span className={classes.curbside}>By CurbsideBell.com</span>
					</div>
				</MatToolbar>
			</AppBar>
		);
	}
}

export default withStyles(styles)(Toolbar);
