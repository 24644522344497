import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const dateFormatter = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric' });

const useStyles = makeStyles({
	root: {
		padding: "6px 6px",
		margin: "10px",
		borderRadius: "5px",
		display: "grid",
		gridGap: "5px 30px",
		gridTemplateColumns: "auto auto",
	},
	store: {
		backgroundColor: "rgb(0 0 0 / 10%)",
		alignSelf: "flex-start",
	},
	customer: {
		backgroundColor: "#b1eeb75e",
		alignSelf: "flex-end",
	},
	message: {
		gridColumn: "1 / -1",
	},
	sentBy: {
		fontSize: "11px",
		color: "#00000085",
	},
	time: {
		fontSize: "11px",
		color: "#00000085",
		justifySelf: "end",
	}
});

export function ChatItem({ chatMessage }) {
	const classes = useStyles();
	var rootClass = `${classes.root} ${chatMessage.sentBy === "store" ? classes.store : classes.customer}`;
	return (
		<div className={rootClass}>
			<span style={{ whiteSpace: "pre-wrap" }} className={classes.message}>{chatMessage.message}</span>
			<span className={classes.sentBy}>{chatMessage.sentBy === "store" ? "Store" : "You"}</span>
			<span className={classes.time}>{dateFormatter.format(chatMessage.dateTimeSent)}</span>
		</div>
	);
}
